.us-redirection-popup {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 150px 15px 0 15px;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
    z-index: 99999999999;

    @media (min-width: 1024px) {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__wrapper {
    display: flex;
    padding: 26px 20px 20px 20px;
    font-family: "Titillium Web", sans-serif;
    background-color: $c-fieldset-border-light;
    flex-direction: column;

    @media (min-width: 1024px) {
      max-width: 350px;
      min-height: 204px;
    }
  }

  &__header {
    position: relative;
  }

  &__header-text {
    color: $c-black;
    font-size: 18px;
  }

  &__close {
    cursor: pointer;
    text-align: center;
    width: 40px;
    float: right;
    display: block;
    color: $c-text;
    text-decoration: none;
    font-weight: normal;
    font-size: 30px;
    position: absolute;
    top: -13px;
    right: -10px;
  }

  &__content {
    margin: 20px 0;
    color: $c-black;
    font-size: 14px;
  }

  &__link {
    padding: 10px 0;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: $c-white;
    background-color: $c-black;

    &:hover {
      outline: 1px solid $c-black;
      background-color: $c-white;
      color: $c-black;
      text-decoration: none;
    }
  }
}
