.breadcrumbs-container-with-title, .breadcrumbs-container {
  padding: 10px 20px;
  background: black;
  margin-bottom: 20px;
  font-weight: bold;

  .category-name {
    color: #f9f9f9;
    font-weight: bold;
  }

  .breadcrumbs {
    margin-bottom: 0;

    ul {
      li {
        font-size: 14px;
      }

      li:last-child {
        strong {
          color: #f9f9f9;
        }
      }
    }
  }
}

.breadcrumbs-container {
  .breadcrumbs {
    margin: 0;
  }
}