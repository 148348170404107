$c : 'sm-newsletter-popup';

.#{$c} {
  display: none;
  &.is-active {
    display: block;
    ~ .#{$c}--overlay {
      display: block;
      visibility: visible;
    }
  }

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  padding: 20px;
  z-index: 10001;
  background: #fff;
}

.#{$c}--overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  display: none;
  z-index: 10000;
  background: rgba(0,0,0,.7);
}

.#{$c}--close {
  width: 40px;
  height: 40px;
  background-color: #f9b100;
  position: absolute;
  right: 0;
  top: -40px;
  transition: .2s background-color;
  cursor: pointer;

  &:hover {
    background-color: #fff;

    &:before, &:after {
      background: #f9b100;
    }
  }

  &:before, &:after {
    background: #fff;
    content: '';
    position: absolute;
    width: 2px;
    height: 18px;
    top: 50%;
    left: 50%;
  }
  &:before {
    transform: translate(-50%,-50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%,-50%) rotate(-45deg)
  }
}

body {}